/**
 * *************************************
 *  SHADAPPS CONFIDENTIAL
 *  __________________
 *
 *  Created by Vittorio Tauro
 *
 *  2022 (c) ShadApps Srl
 *  All Rights Reserved.
 *
 *  NOTICE:  All information contained herein is, and remains
 *  the property of ShadApps Srl and its suppliers,
 *  if any. The intellectual and technical concepts contained
 *  herein are proprietary to ShadApps Srl.
 *  and its suppliers and may be covered by Italian, European and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from ShadApps Srl.
 * *************************************
 * */

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
// utils

import axios from '../../utils/axios';
//
import { dispatch } from '../store';
// routes
import { PATH_API } from '../../routes/api';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    success: false,
    verificationCodes: [],
    verificationCode: [],
};

const slice = createSlice({
    name: 'verificationCode',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.success = false;
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET VERIFICATIONCODES
        getVerificationCodesSuccess(state, action) {
            state.isLoading = false;
            state.verificationCodes = action.payload;
        },

        // CREATE VERIFICATIONCODE
        createVerificationCodeSuccess(state, action) {
            const newVerificationCode = action.payload;
            state.success = true;
            state.isLoading = false;
            state.verificationCodes = [...state.verificationCodes, newVerificationCode];
        },

        // UPDATE VERIFICATIONCODE
        updateVerificationCodeSuccess(state, action) {
            const verificationCode = action.payload;
            const updateVerificationCode = state.verificationCodes.map((_verificationCode) => {
                if (_verificationCode._id === verificationCode._id) {
                    return verificationCode;
                }
                return _verificationCode;
            });

            state.success = true;
            state.isLoading = false;
            state.verificationCodes = updateVerificationCode;
        },

        // DELETE VERIFICATIONCODE
        deleteVerificationCodeSuccess(state, action) {
            const { id } = action.payload;
            const deleteVerificationCode = state.verificationCodes.filter((verificationCode) => verificationCode._id !== id);
            state.verificationCodes = deleteVerificationCode;
            state.isLoading = false;
            state.success = true;
        },

        // SELECT VERIFICATIONCODE
        selectVerificationCode(state, action) {
            const id = action.payload;
            state.isOpenModal = true;
            state.error = null;
            state.success = false;
            state.selectedId = id;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectVerificationCode, createVerificationCodeSuccess, updateVerificationCodeSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getVerificationCodes() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(PATH_API.verificationCodes);
            dispatch(slice.actions.getVerificationCodesSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getVerificationCode(objectId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${PATH_API.verificationCodes}/${objectId}`);
            dispatch(slice.actions.getVerificationCodesSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function createVerificationCode(newVerificationCode) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(PATH_API.verificationCodes, newVerificationCode);
            dispatch(slice.actions.createVerificationCodeSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateVerificationCode({ id, updates }) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${PATH_API.verificationCodes}/${id}`, updates);
            dispatch(slice.actions.updateVerificationCodeSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function deleteVerificationCode(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.delete(`${PATH_API.verificationCodes}/${id}`);
            dispatch(slice.actions.deleteVerificationCodeSuccess({ id }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
