import { MenuItem, Stack } from '@mui/material';
import React, { useState } from 'react';
import { CurrentFlagIcon } from '../../../../components/icon/FlagIcon';
import { useLocale } from '../../../../hooks/custom/useLocale';
import { IconButtonAnimate } from '../../../../components/animate';
import Image from '../../../../components/Image';
import MenuPopover from '../../../../components/MenuPopover';
import { allLangs } from '../../../../config';

export const LocaleMenuItem = ({ sx }) => {
    const [open, setOpen] = useState(null);
    const { t, flanguage, setLanguage, selectedLocale } = useLocale();
    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };
    const handleSelectLanguage = (lang) => {
        setLanguage(lang);
        handleClose();
    };
    return (
        <>
            <MenuItem
                onClick={handleOpen}
                sx={{
                    // px: 0,
                    ...sx,
                    ...(open && { bgcolor: 'action.selected' }),
                }}
            >
                <CurrentFlagIcon />
                {flanguage}
            </MenuItem>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    mt: 1.5,
                    ml: 0.75,
                    width: 180,
                    '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
                }}
            >
                <Stack spacing={0.75}>
                    {allLangs.map((option, i) => (
                        <MenuItem
                            key={option.value}
                            selected={option.value === selectedLocale}
                            onClick={() => handleSelectLanguage(option.value)}
                        >
                            <Image disabledEffect alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />

                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>
            </MenuPopover>
        </>
    );
};
