/**
 * *************************************
 *  SHADAPPS CONFIDENTIAL
 *  __________________
 *
 *  Created by Vittorio Tauro
 *
 *  2022 (c) ShadApps Srl
 *  All Rights Reserved.
 *
 *  NOTICE:  All information contained herein is, and remains
 *  the property of ShadApps Srl and its suppliers,
 *  if any. The intellectual and technical concepts contained
 *  herein are proprietary to ShadApps Srl.
 *  and its suppliers and may be covered by Italian, European and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from ShadApps Srl.
 * *************************************
 * */

// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
}

const API_VERSION = '/api/v1';

// ----------------------------------------------------------------------
// TODO: CREATE
export const PATH_API = {
    login: path(API_VERSION, '/auth/login'),
    me: path(API_VERSION, '/auth/me'),
    employees: path(API_VERSION, '/employees'),
    users: path(API_VERSION, '/users'),
    homeStats: path(API_VERSION, '/home-statistics'),
    devices: path(API_VERSION, '/devices'),
    locations: path(API_VERSION, '/locations'),
    deviceLogs: path(API_VERSION, '/devicelogs'),
    userSettings: path(API_VERSION, '/userSettings'),
    verificationCodes: path(API_VERSION, '/verificationCodes'),
    mobileCredentials: path(API_VERSION, '/mobileCredentials'),
    distinct: path(API_VERSION, '/distinct'),
    auth: path(API_VERSION, '/auth'),
};

export const _PATH_API_NEW = {
    auth: {
        forgotPassword: `${PATH_API.auth}/forgot-password`,
        confirmPasswordNonce: `${PATH_API.auth}/reset-password/confirm-nonce`,
        resetPassword: (token) => `${PATH_API.auth}/reset-password/token/${token}`,
    },
    devices: {
        // flashLED: (_id) => `${PATH_API.devices}/sudo/flash-led/${_id}`,
        testAlert: (_id) => `${PATH_API.devices}/sudo/test-alert/${_id}`,
    },
    mobileCredentials: {
        setCredentials: `${PATH_API.mobileCredentials}/set-credentials`,
    },
    distinct: {
        company: `${PATH_API.distinct}/company`,
    },
};
