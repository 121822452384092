/**
 * *************************************
 *  SHADAPPS CONFIDENTIAL
 *  __________________
 *
 *  Created by Vittorio Tauro
 *
 *  2022 (c) ShadApps Srl
 *  All Rights Reserved.
 *
 *  NOTICE:  All information contained herein is, and remains
 *  the property of ShadApps Srl and its suppliers,
 *  if any. The intellectual and technical concepts contained
 *  herein are proprietary to ShadApps Srl.
 *  and its suppliers and may be covered by Italian, European and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from ShadApps Srl.
 * *************************************
 * */

// @mui
import { Stack, Button, Typography } from '@mui/material';
// assets
import { DocIllustration } from '../../../assets';
import { project } from '../../../json/project';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
    return (
        <Stack spacing={3} sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}>
            <DocIllustration sx={{ width: 1 }}/>

            <div>
                <Typography gutterBottom variant="subtitle1">
                    {project.company}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {project.type}
                    <br/> {project.version}
                </Typography>
            </div>

            <a href={'https://www.shadapps.it'}>
                <img src={'/logo/logo_shadapps.png'} alt={'logo shadapps'}/>
            </a>
        </Stack>
    );
}
