/**
 * *************************************
 *  SHADAPPS CONFIDENTIAL
 *  __________________
 *
 *  Created by Vittorio Tauro
 *
 *  2022 (c) ShadApps Srl
 *  All Rights Reserved.
 *
 *  NOTICE:  All information contained herein is, and remains
 *  the property of ShadApps Srl and its suppliers,
 *  if any. The intellectual and technical concepts contained
 *  herein are proprietary to ShadApps Srl.
 *  and its suppliers and may be covered by Italian, European and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from ShadApps Srl.
 * *************************************
 * */

import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import homeNotificationReducer from './slices/homeNotifications';
import employeeReducer from './slices/employees';
import userReducer from './slices/users';
import deviceReducer from './slices/devices';
import deviceLogsReducer from './slices/deviceLogs';
import userSettingsReducer from './slices/userSettings';
import verificationCodesReducer from './slices/verificationCodes';
import locationsReducer from './slices/locations';
import mobileCredentialReducer from './slices/mobileCredentials.slice';

// ----------------------------------------------------------------------

const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: [],
};

const rootReducer = combineReducers({
    employees: employeeReducer,
    devices: deviceReducer,
    users: userReducer,
    deviceLogs: deviceLogsReducer,
    userSettings: userSettingsReducer,
    verificationCodes: verificationCodesReducer,
    locations: locationsReducer,
    homeNotifications: homeNotificationReducer,
    mobileCredentials: mobileCredentialReducer,
});

export { rootPersistConfig, rootReducer };
