/**
 * *************************************
 *  SHADAPPS CONFIDENTIAL
 *  __________________
 *
 *  Created by Vittorio Tauro
 *
 *  2022 (c) ShadApps Srl
 *  All Rights Reserved.
 *
 *  NOTICE:  All information contained herein is, and remains
 *  the property of ShadApps Srl and its suppliers,
 *  if any. The intellectual and technical concepts contained
 *  herein are proprietary to ShadApps Srl.
 *  and its suppliers and may be covered by Italian, European and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from ShadApps Srl.
 * *************************************
 * */

import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Alert, Box, Card, Container, Link, Stack, Tooltip, Typography } from '@mui/material';
// routes
import { useTranslation } from 'react-i18next';
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import LogoSquare from '../../components/LogoSquare';
import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';
import { COLORS } from '../../utils/getColorPresets';
import LanguagePopover from '../../layouts/dashboard/header/LanguagePopover';
import { LocaleMenuItem } from '../../layouts/dashboard/navbar/components/LocaleMenuItem';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 7),
    },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
    const { method } = useAuth();
    const { t, i18n } = useTranslation();
    const smUp = useResponsive('up', 'sm');

    const mdUp = useResponsive('up', 'md');
    return (
        <Page title="Login">
            <RootStyle>
                <HeaderStyle>
                    <Logo />
                    <LocaleMenuItem sx={{ px: 2 }} />
                </HeaderStyle>

                {mdUp && (
                    <SectionStyle>
                        <Typography
                            variant="h2"
                            sx={(theme) => ({
                                color: COLORS.red,
                                px: 5,
                                mt: 10,
                                mb: 5,
                            })}
                        >
                            {t('Welcome Back!')}
                        </Typography>
                        <Image
                            visibleByDefault
                            disabledEffect
                            src="/assets/illustrations/illustration_login.png"
                            alt="login"
                        />
                    </SectionStyle>
                )}

                <Container maxWidth="sm">
                    <ContentStyle>
                        <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="h4" color={COLORS.redYellow} gutterBottom>
                                    {t('Access the ExpoAlert Dashboard')}
                                </Typography>
                                <Typography sx={{ color: 'text.secondary' }}>
                                    {t('Inserisci le tue credenziali qui sotto.')}
                                </Typography>
                            </Box>

                            {/* <Tooltip title={capitalCase(method)} placement="right">
                                <LogoSquare sx={{ width: 150, height: 150 }} />
                            </Tooltip> */}
                        </Stack>

                        <LoginForm />
                    </ContentStyle>
                </Container>
            </RootStyle>
        </Page>
    );
}
