/**
 * *************************************
 *  SHADAPPS CONFIDENTIAL
 *  __________________
 *
 *  Created by Vittorio Tauro
 *
 *  2022 (c) ShadApps Srl
 *  All Rights Reserved.
 *
 *  NOTICE:  All information contained herein is, and remains
 *  the property of ShadApps Srl and its suppliers,
 *  if any. The intellectual and technical concepts contained
 *  herein are proprietary to ShadApps Srl.
 *  and its suppliers and may be covered by Italian, European and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from ShadApps Srl.
 * *************************************
 * */

// routes
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { ModalContextProvider } from './contexts/ModalContext';

import './locales/i18nConfig'; // This runs the configuration code in i18nConfig.js
import { useLocale } from './hooks/custom/useLocale';

// ----------------------------------------------------------------------

export default function App() {
    useLocale(); // initialize i18n
    return (
        <MotionLazyContainer>
            <ThemeProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <ThemeSettings>
                        <NotistackProvider>
                            <ModalContextProvider>
                                <ProgressBarStyle />
                                <ScrollToTop />
                                <Router />
                            </ModalContextProvider>
                        </NotistackProvider>
                    </ThemeSettings>
                </LocalizationProvider>
            </ThemeProvider>
        </MotionLazyContainer>
    );
}
