/**
 * *************************************
 *  SHADAPPS CONFIDENTIAL
 *  __________________
 *
 *  Created by Vittorio Tauro
 *
 *  2022 (c) ShadApps Srl
 *  All Rights Reserved.
 *
 *  NOTICE:  All information contained herein is, and remains
 *  the property of ShadApps Srl and its suppliers,
 *  if any. The intellectual and technical concepts contained
 *  herein are proprietary to ShadApps Srl.
 *  and its suppliers and may be covered by Italian, European and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from ShadApps Srl.
 * *************************************
 * */

import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography, Avatar, MenuItem } from '@mui/material';
// hooks
import { useTranslation } from 'react-i18next';
import useAuth from '../../../hooks/useAuth';
import { allLangs } from '../../../config';
import Image from '../../../components/Image';
import { CurrentFlagIcon } from '../../../components/icon/FlagIcon';
import { useLocale } from '../../../hooks/custom/useLocale';
import { LocaleMenuItem } from './components/LocaleMenuItem';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.grey[500_12],
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter,
    }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
    isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
    // Logic here to get current user role
    const { user } = useAuth();
    const { t, flanguage } = useLocale();

    return (
        <Link underline="none" color="inherit">
            <RootStyle
                sx={{
                    ...(isCollapse && {
                        bgcolor: 'transparent',
                    }),
                }}
            >
                <Avatar src="/assets/images/avatar.jpeg" alt={`${user.name} ${user.surname}`} />

                <Box
                    sx={{
                        ml: 2,
                        transition: (theme) =>
                            theme.transitions.create('width', {
                                duration: theme.transitions.duration.shorter,
                            }),
                        ...(isCollapse && {
                            ml: 0,
                            width: 0,
                        }),
                    }}
                >
                    <Typography variant="subtitle2" noWrap>
                        {`${user.name} ${user.surname || ''}`}
                    </Typography>
                    <Typography variant="body2" noWrap sx={{ color: 'text.secondary', textTransform: 'uppercase' }}>
                        {`${user.company || ''}`}
                    </Typography>
                    <LocaleMenuItem />
                </Box>
            </RootStyle>
        </Link>
    );
}
