// theme
import palette from '../theme/palette';

// ----------------------------------------------------------------------

function createGradient90(color1, color2) {
    return `linear-gradient(90deg, ${color1}, 0%, ${color2}, 100%)`;
}
export const COLORS = {
    /** DEFAULT PURPLE BY LUCA '#7F4191' */
    violet: '#7F4191',
    pinkViolet: '#991f58',
    whiteViolet: '#8b3c83',
    /** DEFAULT RED BY LUCA '#B81660' */
    red: '#B81660',
    /** DEFAULT RED/ORANGISH BY LUCA: table section header '#CD283E' */
    redYellow: '#CD283E',
    grey: '#737373',
};
export const colorPresets = [
    // DEFAULT
    {
        name: 'default',
        ...palette.light.primary,
    },
    // PURPLE
    {
        name: 'purple',
        lighter: '#EBD6FD',
        light: '#B985F4',
        main: '#7635dc',
        dark: '#431A9E',
        darker: '#200A69',
        contrastText: '#fff',
    },
    // CYAN
    {
        name: 'cyan',
        lighter: '#D1FFFC',
        light: '#76F2FF',
        main: '#1CCAFF',
        dark: '#0E77B7',
        darker: '#053D7A',
        contrastText: palette.light.grey[800],
    },
    // BLUE
    {
        name: 'blue',
        lighter: '#D1E9FC',
        light: '#76B0F1',
        main: '#2065D1',
        dark: '#103996',
        darker: '#061B64',
        contrastText: '#fff',
    },
    // ORANGE
    {
        name: 'orange',
        lighter: '#FEF4D4',
        light: '#FED680',
        main: '#fda92d',
        dark: '#B66816',
        darker: '#793908',
        contrastText: palette.light.grey[800],
    },
    // RED
    {
        name: 'red',
        lighter: '#FFE3D5',
        light: '#FFC1AC',
        main: '#FF3030',
        dark: '#B71833',
        darker: '#7A0930',
        contrastText: '#fff',
    },
    // EXPOALERT
    // {
    //     name: 'expoalert',
    //     lighter: '#fefefe',
    //     light: '#af325e',
    //     main: '#8b3c6f',
    //     dark: '#991f58',
    //     darker: '#8b3c83',
    //     gradient0:'#8b3c83',
    //     gradient1:'#cc263c'
    // }
    {
        name: 'expoalert',
        lighter: '#fefefe',
        light: COLORS.redYellow,
        main: COLORS.violet,
        dark: COLORS.pinkViolet,
        darker: COLORS.whiteViolet,
        darkRed: COLORS.red,
        gradient0: COLORS.red,
        gradient1: COLORS.redYellow,
        // contrastText: palette.light.grey[800],
    },
];

// expoalert color color config (expoalert color config)

export const defaultPreset = colorPresets[0];
export const purplePreset = colorPresets[1];
export const cyanPreset = colorPresets[2];
export const bluePreset = colorPresets[3];
export const orangePreset = colorPresets[4];
export const redPreset = colorPresets[5];
export const expoAlertPreset = colorPresets[6];

export default function getColorPresets(presetsKey) {
    return {
        purple: purplePreset,
        cyan: cyanPreset,
        blue: bluePreset,
        orange: orangePreset,
        red: redPreset,
        expoalert: expoAlertPreset,
        default: defaultPreset,
    }[presetsKey];
}
