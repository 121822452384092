import React from 'react';
import { useTranslation } from 'react-i18next';

export const useLocale = () => {
    const { t, i18n } = useTranslation();
    const { language } = i18n;

    const [selectedLocale, setSelectedLocale] = React.useState('en');

    React.useEffect(() => {
        const locale = localStorage.getItem('locale');
        if (locale) {
            i18n.changeLanguage(locale);
            setSelectedLocale(locale);
        }
    }, []);

    const handleSetLanguage = (lang) => {
        localStorage.setItem('locale', lang);
        i18n.changeLanguage(lang);
        setSelectedLocale(lang);
    };

    return {
        t,
        i18n,
        language,
        flanguage: language.toUpperCase(),
        setLanguage: handleSetLanguage,
        selectedLocale,
    };
};
