/**
 * *************************************
 *  SHADAPPS CONFIDENTIAL
 *  __________________
 *
 *  Created by Vittorio Tauro
 *
 *  2022 (c) ShadApps Srl
 *  All Rights Reserved.
 *
 *  NOTICE:  All information contained herein is, and remains
 *  the property of ShadApps Srl and its suppliers,
 *  if any. The intellectual and technical concepts contained
 *  herein are proprietary to ShadApps Srl.
 *  and its suppliers and may be covered by Italian, European and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from ShadApps Srl.
 * *************************************
 * */

import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
// routes
import { PATH_API } from '../../routes/api';

// ======================================================================
// POTEVO FARE FETCH IN HOME MA HO DECISO DI FARE FETCH IN REDUX COME LE ALTRE PAGINE
// ======================================================================
const initialState = {
    isLoading: false,
    error: null,
    success: false,
    homeNotifications: [],
    homeNotification: [],

};

const slice = createSlice({
    name: 'homeNotification',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET HOME_NOTIFICATIONS
        getHomeNotificationsSuccess(state, action) {
            state.isLoading = false;
            state.homeNotifications = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

export function getHomeNotifications() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${PATH_API.notification}/documents-status`);
            dispatch(slice.actions.getHomeNotificationsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
