export const en = {
    translation: {
        'Welcome to React': 'Welcome to React and react-i18next',
        'Access the ExpoAlert Dashboard': 'Access the ExpoAlert Dashboard',
        'Welcome Back!': 'Welcome Back!',
        'Inserisci le tue credenziali qui sotto.': 'Insert your credentials below.',
        'Password dimenticata?': 'Forgot your password?',
        'Ricordati di me': 'Remember me',
        'Righe per pagina': 'Rows per page',
        Conferma: 'Confirm',
        elimina: 'delete',
        Elimina: 'Delete',
        Modifica: 'Edit',
        modifica: 'edit',
        Blocca: 'Lock',
        Sblocca: 'Unlock',
        'Reset(idle)': 'Reset(idle)',
        'Flash dispositivo': 'Flash device',
        Cerca: 'Search',
        Descrizione: 'Description',
        Ruolo: 'Role',
        'Operazione completata!': 'Operation completed!',
    },
};
