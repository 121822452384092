import { Fragment } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader, Tooltip, Typography } from '@mui/material';

// HOOKS
import useAuth from '../../../hooks/useAuth';
//
import { NavListRoot } from './NavList';
import { ListItemIconStyle, ListItemTextStyle } from './style';
import { ICONS } from '../../../layouts/dashboard/navbar/NavConfig';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
    ({ theme }) => ({
        ...theme.typography.overline,
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        color: theme.palette.text.primary,
        transition: theme.transitions.create('opacity', {
            duration: theme.transitions.duration.shorter,
        }),
    })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
    isCollapse: PropTypes.bool,
    navConfig: PropTypes.array,
};

export default function NavSectionVertical({ isHome, navConfig, isCollapse = false, ...other }) {
    const { user } = useAuth();
    return (
        <Box {...other}>
            {navConfig.map((group) => (
                <Fragment key={group.id}>
                    {group.roles.includes(user.role) && (
                        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
                            <ListSubheaderStyle
                                sx={{
                                    ...(isCollapse && {
                                        opacity: 0,
                                    }),
                                }}
                            >
                                {group.subheader}
                            </ListSubheaderStyle>

                            {group.items.map(
                                (list) =>
                                    list.roles.includes(user.role) && (
                                        <NavListRoot key={list.title + list.path} list={list} isCollapse={isCollapse} />
                                    )
                            )}
                        </List>
                    )}
                </Fragment>
            ))}
        </Box>
    );
}
