import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import LoadingScreen from '../components/LoadingScreen';

export const Logout = () => {
    const {logout} = useAuth();
    const navigate = useNavigate();
    useEffect(() => {
        logout();
        navigate('/login');
    }, []);
    return (
        <LoadingScreen/>    );
};
