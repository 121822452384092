/**
 * *************************************
 *  SHADAPPS CONFIDENTIAL
 *  __________________
 *
 *  Created by Vittorio Tauro
 *
 *  2022 (c) ShadApps Srl
 *  All Rights Reserved.
 *
 *  NOTICE:  All information contained herein is, and remains
 *  the property of ShadApps Srl and its suppliers,
 *  if any. The intellectual and technical concepts contained
 *  herein are proprietary to ShadApps Srl.
 *  and its suppliers and may be covered by Italian, European and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from ShadApps Srl.
 * *************************************
 * */

// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import Iconify from '../../../components/Iconify';
import { ROUTE_DASHBORD } from '../../../routes';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const getIconify = (name) => <Iconify icon={name} sx={{ width: 1, height: 1 }} />;

export const ICONS = {
    blog: getIcon('ic_blog'),
    cart: getIcon('ic_cart'),
    chat: getIcon('ic_chat'),
    mail: getIcon('ic_mail'),
    user: getIcon('ic_user'),
    kanban: getIcon('ic_kanban'),
    banking: getIcon('ic_banking'),
    booking: getIcon('ic_booking'),
    invoice: getIcon('ic_invoice'),
    calendar: getIcon('ic_calendar'),
    ecommerce: getIcon('ic_ecommerce'),
    analytics: getIcon('ic_analytics'),
    dashboard: getIcon('ic_dashboard'),
    menuItem: getIcon('ic_menu_item'),
    car: getIconify('mdi:car'),
    buildings: getIconify('mdi:domain'),
    work: getIconify('mdi:hammer-wrench'),
    employees: getIconify('mdi:account-hard-hat-outline'),
    assignment: getIconify('mdi:arrow-decision-outline'),
    download: getIconify('mdi:download-outline'),
    key: getIconify('material-symbols:key'),
    device: getIconify('mdi:cellphone-wireless'),
    history: getIconify('mdi:clipboard-text-clock-outline'),
    location: getIconify('mdi:location-multiple-outline'),
    setting: getIconify('mdi:cog'),
    qrCode: getIconify('mdi:qrcode'),
    logout: getIconify('mdi:logout'),
};

const navConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
        id: 0,
        roles: ['admin', 'userb2b', 'userb2c'],
        subheader: 'Menu',
        items: [
            { title: 'Home', path: '/home', icon: ICONS.dashboard, roles: ['admin', 'userb2b', 'userb2c'] },
            // B2B
            { title: 'Team Members', path: '/employees', icon: ICONS.employees, roles: ['admin', 'userb2b'] },
            { title: 'sensors', path: '/devices', icon: ICONS.device, roles: ['admin', 'userb2b', 'userb2c'] },
            {
                title: 'Event Register',
                path: '/devicelogs',
                icon: ICONS.history,
                roles: ['admin', 'userb2b', 'userb2c'],
            },
            { title: 'Locations', path: '/locations', icon: ICONS.location, roles: ['admin', 'userb2b', 'userb2c'] },
        ],
    },

    // MANAGEMENT
    // ----------------------------------------------------------------------

    {
        id: 1,
        roles: ['admin'],
        subheader: 'utility',
        items: [
            { title: 'Users', path: '/users', icon: ICONS.user, roles: ['admin'] },
            // { title: 'Impostazioni users', path: '/usersettings', icon: ICONS.setting, roles: ['admin'] },
            // { title: 'VerificationCodes', path: '/verificationcodes', icon: ICONS.qrCode, roles: ['admin'] },
        ],
    },

    {
        id: 3,
        roles: ['admin', 'userb2b', 'userb2c'],
        subheader: 'Account',
        items: [
            {
                title: 'Admin Settings',
                path: `/mobileCredentials`,
                icon: ICONS.key,
                roles: ['admin', 'userb2b', 'userb2c'],
            },
            {
                title: 'Logout',
                path: `/logout`,
                icon: ICONS.logout,
                roles: ['admin', 'userb2b', 'userb2c'],
                isHome: true,
            },
        ],
    },
];

export default navConfig;
