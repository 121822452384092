import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const useStore = () => {
    // per modal
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const [modalEntity, setModalEntity] = useState('');
    const [extraModalData, setExtraModalData] = useState({});
    const [modalType, setModalType] = useState('');
    return {
        modalIsOpen,
        openModal: (type = '') => {
            setModalType(type);
            setModalIsOpen(true);

        },
        closeModal: () => {
            setModalData({});
            // setModalEntity('');
            setModalIsOpen(false);},
        toggleOpenModal: () => setModalIsOpen(prev => !prev),
        modalData,
        setModalData: (data) => setModalData(data),
        modalEntity,
        setModalEntity: (entity) => setModalEntity(entity),
        extraModalData,
        setExtraModalData: (data) => setExtraModalData(data),
        modalType,
    };
};

const ModalContext = createContext({
    modalIsOpen: false,
    openModal: () => {},
    closeModal: () => {},
    toggleOpenModal: () => {},
    filterFunction: () => {},
    modalData: {},
    setModalData: () => {},
    modalEntity: '',
    setModalEntity: () => {},
    extraModalData: {},
    setExtraModalData: () => {},
    modalType: '',
});

export const ModalContextProvider = ({ children }) => (
    <ModalContext.Provider value={useStore()} >
        {children}
    </ModalContext.Provider>
);

ModalContextProvider.propTypes = {
    children: PropTypes.node,
};
// use all modal context

// modal open close controller
export const useModalIsOpen = () => useContext(ModalContext).modalIsOpen;
export const useOpenModal = () => useContext(ModalContext).openModal;
export const useCloseModal = () => useContext(ModalContext).closeModal;
export const useToggleOpenModal = () => useContext(ModalContext).toggleOpenModal;

// modal data inside form
export const useModalData = () => useContext(ModalContext).modalData;
export const useSetModalData = () => useContext(ModalContext).setModalData;

// modal entity. entity inside modal
export const useModalEntity = () => useContext(ModalContext).modalEntity;
export const useSetModalEntity = () => useContext(ModalContext).setModalEntity;

export const useExtraModalData = () => useContext(ModalContext).extraModalData;
export const useSetExtraModalData = () => useContext(ModalContext).setExtraModalData;

export const useModalType = () => useContext(ModalContext).modalType;

// use this to import easily
export const useModalContext = () => (
    {
        modalIsOpen: useModalIsOpen(),
        openModal: useOpenModal(),
        closeModal: useCloseModal(),
        toggleOpenModal: useToggleOpenModal(),
        modalData: useModalData(),
        setModalData: useSetModalData(),
        modalEntity: useModalEntity(),
        setModalEntity: useSetModalEntity(),
        extraModalData: useExtraModalData(),
        setExtraModalData: useSetExtraModalData(),
        modalType: useModalType(),
    }
);
