/**
 * *************************************
 *  SHADAPPS CONFIDENTIAL
 *  __________________
 *
 *  Created by Vittorio Tauro
 *
 *  2022 (c) ShadApps Srl
 *  All Rights Reserved.
 *
 *  NOTICE:  All information contained herein is, and remains
 *  the property of ShadApps Srl and its suppliers,
 *  if any. The intellectual and technical concepts contained
 *  herein are proprietary to ShadApps Srl.
 *  and its suppliers and may be covered by Italian, European and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from ShadApps Srl.
 * *************************************
 * */

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
// utils

import axios from '../../utils/axios';
//
import { dispatch } from '../store';
// routes
import { PATH_API } from '../../routes/api';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    success: false,
    employees: [],
    employee: [],
};

const slice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
    // START LOADING
        startLoading(state) {
            state.success = false;
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET EMPLOYEES
        getEmployeesSuccess(state, action) {
            state.isLoading = false;
            state.employees = action.payload;
        },

        // CREATE EMPLOYEE
        createEmployeeSuccess(state, action) {
            const newEmployee = action.payload;
            state.success = true;
            state.isLoading = false;
            state.employees = [...state.employees, newEmployee];
        },

        // UPDATE EMPLOYEE
        updateEmployeeSuccess(state, action) {
            const employee = action.payload;
            const updateEmployee = state.employees.map((_employee) => {
                if (_employee._id === employee._id) {
                    return employee;
                }
                return _employee;
            });

            state.success = true;
            state.isLoading = false;
            state.employees = updateEmployee;
        },

        // DELETE EMPLOYEE
        deleteEmployeeSuccess(state, action) {
            const { id } = action.payload;
            const deleteEmployee = state.employees.filter((employee) => employee._id !== id);
            state.employees = deleteEmployee;
            state.isLoading = false;
            state.success = true;
        },

        // SELECT EMPLOYEE
        selectEmployee(state, action) {
            const id = action.payload;
            state.isOpenModal = true;
            state.error = null;
            state.success = false;
            state.selectedId = id;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEmployee, createEmployeeSuccess, updateEmployeeSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getEmployees() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(PATH_API.employees);
            dispatch(slice.actions.getEmployeesSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getEmployee(objectId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${PATH_API.employees}/${objectId}`);
            dispatch(slice.actions.getEmployeesSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function createEmployee(newEmployee) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(PATH_API.employees, newEmployee);
            dispatch(slice.actions.createEmployeeSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateEmployee({ id, updates }) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${PATH_API.employees}/${id}`, updates);
            dispatch(slice.actions.updateEmployeeSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function deleteEmployee(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.delete(`${PATH_API.employees}/${id}`);
            dispatch(slice.actions.deleteEmployeeSuccess({ id }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
