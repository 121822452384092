import React from 'react';
import { useTranslation } from 'react-i18next';
import Image from '../Image';
import { allLangs } from '../../config';

export const CurrentFlagIcon = () => {
    const { i18n } = useTranslation();
    const src = allLangs.find((lang) => lang.value === i18n.language)?.icon;
    return <Image disabledEffect alt="locale" src={src} sx={{ width: 28, mr: 2 }} />;
};
