export const it = {
    translation: {
        'Welcome to React': 'Benvenuto in React e react-i18next',
        'Access the ExpoAlert Dashboard': 'Accedi alla Dashboard di ExpoAlert',
        'Welcome Back!': 'Bentornati!',
        'Welcome to your ExpoAlert Dashboard, the platform to manage and monitor your smart sensors':
            'Benvenuti alla vostra ExpoAlert Dashboard, la piattaforma per gestire e monitorare i vostri sensori.',
        'Inserisci le tue credenziali qui sotto.': 'Inserisci le tue credenziali qua sotto.',
        'Password dimenticata?': 'Password dimenticata?',
        'Ricordati di me': 'Ricordati di me',
        'Righe per pagina': 'Righe per pagina',
        Conferma: 'Conferma',
        'Team Members': 'Membri del Team',
        sensors: 'Sensori',
        'Event Register': 'Registro Eventi',
        utility: 'Utilità',
        Users: 'Utenti',
        'Admin Settings': 'Impostazioni Admin',
        'Number of Smart Sensors': 'Numero di Sensori',
        'Alerts in the Last 30 Days': 'Allarmi negli ultimi 30 giorni',
        'Smart Sensors': 'Sensori',
        'Quick search': 'Ricerca rapida',
        Name: 'Nome',
        Surname: 'Cognome',
        Phone: 'Telefono',
        of: 'di',
        'more than': 'più di',
        'Add Team Member': 'Aggiungi Membro del Team',
        'Add sensor': 'Aggiungi Sensore',
        Sensor: 'Sensore',
        Sensors: 'Sensori',
        Active: 'Attivo',
        Disabled: 'Disabilitato',
        'Admin user': 'Utente Admin',
        All: 'Tutti',
        'Serial Number': 'Numero di Serie',
        Status: 'Stato',
        Battery: 'Batteria',
        Weight: 'Peso',
        'Sensitivity (1-63)': 'Sensibilità (1-63)',
        'Initial Weight (in Grams)': 'Peso iniziale (in grammi)',
        'Heartbeat (in Seconds)': 'Heartbeat (in Secondi)',
        From: 'Da',
        To: 'A',
        'Smart Sensor': 'Sensore',
        'Team Member': 'Membro del Team',
        Date: 'Data',
        Save: 'Salva',
        Companies: 'Aziende',
        Company: 'Azienda',
        '# of Smart Sensors': 'Numero di Sensori',
        'Full Name': 'Nome e Cognome',
        Role: 'Ruolo',
        'Add User': 'Aggiungi Utente',
        'Add Location': 'Aggiungi Location',
        'Create/edit': 'Crea/modifica',
        User: 'Utente',
        Add: 'Aggiungi',
        Locations: 'Location',
        Location: 'Location',
        location: 'location',
        locations: 'location',
        event: 'evento',
        Event: 'Evento',
        'Low Battery': 'Batteria Scarica',
        'Insert 6 digits code to reset password': 'Inserisci il codice di 6 cifre per resettare la password',
        'Reset password': 'Resetta password',
        '6 digits code': 'Codice di 6 cifre',
        'Something went wrong': 'Qualcosa è andato storto',
        'Please input both fields': 'Inserisci entrambi i campi',
        'Update Success': 'Aggiornamento riuscito',
        'Error: something went wrong': 'Errore: qualcosa è andato storto',
        'Unable to logout!': 'Impossibile effettuare il logout!',
        'Invalid credentials': 'Credenziali non valide',
        Buzzer: 'Cicalino',
        unlock: 'sbloccato',
        lock: 'bloccato',
        // Location:
    },
};
