/**
 * *************************************
 *  SHADAPPS CONFIDENTIAL
 *  __________________
 *
 *  Created by Vittorio Tauro
 *
 *  2022 (c) ShadApps Srl
 *  All Rights Reserved.
 *
 *  NOTICE:  All information contained herein is, and remains
 *  the property of ShadApps Srl and its suppliers,
 *  if any. The intellectual and technical concepts contained
 *  herein are proprietary to ShadApps Srl.
 *  and its suppliers and may be covered by Italian, European and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from ShadApps Srl.
 * *************************************
 * */

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
// utils

import axios from '../../utils/axios';
//
import { dispatch } from '../store';
// routes
import { PATH_API } from '../../routes/api';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    success: false,
    users: [],
    user: [],
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.success = false;
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET USERS
        getUsersSuccess(state, action) {
            state.isLoading = false;
            state.users = action.payload;
        },

        // CREATE USER
        createUserSuccess(state, action) {
            const newUser = action.payload;
            state.success = true;
            state.isLoading = false;
            state.users = [...state.users, newUser];
        },

        // UPDATE USER
        updateUserSuccess(state, action) {
            const user = action.payload;
            const updateUser = state.users.map((_user) => {
                if (_user._id === user._id) {
                    return user;
                }
                return _user;
            });

            state.success = true;
            state.isLoading = false;
            state.users = updateUser;
        },

        // DELETE USER
        deleteUserSuccess(state, action) {
            const { userId } = action.payload;
            const deleteUser = state.users.filter((user) => user._id !== userId);
            state.users = deleteUser;
            state.isLoading = false;
            state.success = true;
        },

        // SELECT USER
        selectUser(state, action) {
            const userId = action.payload;
            state.isOpenModal = true;
            state.error = null;
            state.success = false;
            state.selectedId = userId;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectUser, createUserSuccess, updateUserSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getUsers() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(PATH_API.users);
            dispatch(slice.actions.getUsersSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getUser(objectId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${PATH_API.users}/${objectId}`);
            dispatch(slice.actions.getUsersSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function createUser(newUser) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(PATH_API.users, newUser);
            dispatch(slice.actions.createUserSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateUser({ id, updates }) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${PATH_API.users}/${id}`, updates);
            dispatch(slice.actions.updateUserSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function deleteUser(userId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.delete(`${PATH_API.users}/${userId}`);
            dispatch(slice.actions.deleteUserSuccess({ userId }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
