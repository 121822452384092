/**
 * *************************************
 *  SHADAPPS CONFIDENTIAL
 *  __________________
 *
 *  Created by Vittorio Tauro
 *
 *  2022 (c) ShadApps Srl
 *  All Rights Reserved.
 *
 *  NOTICE:  All information contained herein is, and remains
 *  the property of ShadApps Srl and its suppliers,
 *  if any. The intellectual and technical concepts contained
 *  herein are proprietary to ShadApps Srl.
 *  and its suppliers and may be covered by Italian, European and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from ShadApps Srl.
 * *************************************
 * */

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
// utils

import axios from '../../utils/axios';
//
import { dispatch } from '../store';
// routes
import { PATH_API } from '../../routes/api';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    success: false,
    userSettings: [],
    userSetting: [],
};

const slice = createSlice({
    name: 'userSetting',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.success = false;
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET usersettingS
        getUserSettingsSuccess(state, action) {
            state.isLoading = false;
            state.userSettings = action.payload;
        },

        // CREATE usersetting
        createUserSettingSuccess(state, action) {
            const newUserSetting = action.payload;
            state.success = true;
            state.isLoading = false;
            state.userSettings = [...state.userSettings, newUserSetting];
        },

        // UPDATE usersetting
        updateUserSettingSuccess(state, action) {
            const userSetting = action.payload;
            const updateUserSetting = state.userSettings.map((_userSetting) => {
                if (_userSetting._id === userSetting._id) {
                    return userSetting;
                }
                return _userSetting;
            });

            state.success = true;
            state.isLoading = false;
            state.userSettings = updateUserSetting;
        },

        // DELETE usersetting
        deleteUserSettingSuccess(state, action) {
            const { id } = action.payload;
            const deleteUserSetting = state.userSettings.filter((userSetting) => userSetting._id !== id);
            state.userSettings = deleteUserSetting;
            state.isLoading = false;
            state.success = true;
        },

        // SELECT usersetting
        selectUserSetting(state, action) {
            const id = action.payload;
            state.isOpenModal = true;
            state.error = null;
            state.success = false;
            state.selectedId = id;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectUserSetting, createUserSettingSuccess, updateUserSettingSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getUserSettings() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(PATH_API.userSettings);
            dispatch(slice.actions.getUserSettingsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getUserSetting(objectId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${PATH_API.userSettings}/${objectId}`);
            dispatch(slice.actions.getUserSettingsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function createUserSetting(newUserSetting) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(PATH_API.userSettings, newUserSetting);
            dispatch(slice.actions.createUserSettingSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateUserSetting({ id, updates }) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${PATH_API.userSettings}/${id}`, updates);
            dispatch(slice.actions.updateUserSettingSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function deleteUserSetting(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.delete(`${PATH_API.userSettings}/${id}`);
            dispatch(slice.actions.deleteUserSettingSuccess({ id }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
