/**
 * *************************************
 *  SHADAPPS CONFIDENTIAL
 *  __________________
 *
 *  Created by Vittorio Tauro
 *
 *  2022 (c) ShadApps Srl
 *  All Rights Reserved.
 *
 *  NOTICE:  All information contained herein is, and remains
 *  the property of ShadApps Srl and its suppliers,
 *  if any. The intellectual and technical concepts contained
 *  herein are proprietary to ShadApps Srl.
 *  and its suppliers and may be covered by Italian, European and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from ShadApps Srl.
 * *************************************
 * */

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
// utils

import axios from '../../utils/axios';
//
import { dispatch } from '../store';
// routes
import { PATH_API } from '../../routes/api';

import { adminSecret } from '../../config';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    success: false,
    devices: [],
    device: [],
};

const slice = createSlice({
    name: 'device',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.success = false;
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET DEVICES
        getDevicesSuccess(state, action) {
            state.isLoading = false;
            state.devices = action.payload;
        },

        // CREATE DEVICE
        createDeviceSuccess(state, action) {
            const newDevice = action.payload;
            state.success = true;
            state.isLoading = false;
            state.devices = [...state.devices, newDevice];
        },

        // UPDATE DEVICE
        updateDeviceSuccess(state, action) {
            const device = action.payload;
            const updateDevice = state.devices.map((_device) => {
                if (_device._id === device._id) {
                    return device;
                }
                return _device;
            });

            state.success = true;
            state.isLoading = false;
            state.devices = updateDevice;
        },

        // DELETE DEVICE
        deleteDeviceSuccess(state, action) {
            const { id } = action.payload;
            const deleteDevice = state.devices.filter((device) => device._id !== id);
            state.devices = deleteDevice;
            state.isLoading = false;
            state.success = true;
        },

        // SELECT DEVICE
        selectDevice(state, action) {
            const id = action.payload;
            state.isOpenModal = true;
            state.error = null;
            state.success = false;
            state.selectedId = id;
        },

        // LOCK device
        lockDeviceSuccess(state, action) {
            const { id } = action.payload;
            const foundDevice = state.devices.filter((device) => device._id !== id);
            state.devices = deleteDevice;
            state.isLoading = false;
            state.success = true;
        }
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { selectDevice, createDeviceSuccess, updateDeviceSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getDevices() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(PATH_API.devices);
            dispatch(slice.actions.getDevicesSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getDevice(objectId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${PATH_API.devices}/${objectId}`);
            dispatch(slice.actions.getDevicesSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function createDevice(newDevice) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(PATH_API.devices, newDevice);
            dispatch(slice.actions.createDeviceSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateDevice({ id, updates }) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${PATH_API.devices}/${id}`, { ...updates, secret: adminSecret });
            dispatch(slice.actions.updateDeviceSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function deleteDevice(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.delete(`${PATH_API.devices}/${id}`);
            dispatch(slice.actions.deleteDeviceSuccess({ id }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

//= ========================================================================
// DEVICE SPECIFIC ACTIONS
//= ========================================================================

// ----------------------------------------------------------------------

/**
 * first time lock
 * @param updates {object} - {
 * boxOpened: boolean,
 * boxQuantity: number
 * device: objectId <string>,
 * quantity:number
 * }
 * */
export function lockDevice(  updates  ) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${PATH_API.devices}/sudo/lock/${updates.device}`, { ...updates, secret: adminSecret });
            dispatch(slice.actions.updateDeviceSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

/**
 * first time lock
 * @param updates {object} - {
 * boxOpened: boolean,
 * boxQuantity: number
 * device: objectId <string>,
 * quantity:number
 * }
 * */
export function lockDeviceFirstTime(  updates  ) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${PATH_API.devices}/sudo/first-time-lock/${updates.device}`, { ...updates, secret: adminSecret });
            dispatch(slice.actions.updateDeviceSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
/**
 * first time lock
 * @param updates {object} - {
 * boxOpened: boolean,
 * boxQuantity: number
 * device: objectId <string>,
 * quantity:number
 * }
 * */
export const registerSingleWeight = (data) => async() => {
    dispatch(slice.actions.startLoading());
    try {
        const response = await axios.post(
            `${PATH_API.devices}/sudo/register-single-weight/${data.device}`,
            {
                secret: adminSecret,
                ...data
            });
        dispatch(slice.actions.updateDeviceSuccess(response.data.data));

    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
};

// ----------------------------------------------------------------------

/**
 * first time lock
 * @param updates {object} - {
 * boxOpened: boolean,
 * boxQuantity: number
 * device: objectId <string>,
 * quantity:number
 * }
 * */
export function unlockDevice(updates) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${PATH_API.devices}/sudo/unlock/${updates.device}`, { ...updates, secret: adminSecret });
            dispatch(slice.actions.updateDeviceSuccess(response.data.data));

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function emergencyLock(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${PATH_API.devices}/emergency-lock`);
            dispatch(slice.actions.updateDeviceSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

/**
 *
 * @param {String} id
 * @returns {void} -redux dispatch event
 * Pre-reset.
 * Send Email with nonce and tokened link for confirmation form in Frontend.
 */
export function resetDevice(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${PATH_API.devices}/pre-reset/${id}`, { secret: adminSecret });
            dispatch(slice.actions.updateDeviceSuccess(response.data.data));

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

/**
 *
 * @param {String} id
 * @returns {void} -redux dispatch event
 * Pre-reset.
 * Send Email with nonce and tokened link for confirmation form in Frontend.
 */
export function idleDevice(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${PATH_API.devices}/status-idle/${id}`, { secret: adminSecret });
            dispatch(slice.actions.updateDeviceSuccess(response.data.data));

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function confirmResetDevice({ device, nonce, token }) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${PATH_API.devices}/reset-confirm/${token}`, { token, device, nonce });
            dispatch(slice.actions.updateDeviceSuccess(response.data.data));

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export const useUnlockDevice = () => (updates) => dispatch(unlockDevice(updates));
export const useLockDevice = () => (updates) => dispatch(lockDevice(updates));
export const useLockDeviceFirstTime = () => (updates) => dispatch(lockDeviceFirstTime(updates));
export const useRegisterSingleWeight = () => (updates) => dispatch(registerSingleWeight(updates));
export const useResetDevice = () => (id) => dispatch(resetDevice(id));
export const useIdleDevice = () => (id) => dispatch(idleDevice(id));
export const useConfirmResetDevice = () => (data) =>dispatch(confirmResetDevice(data));
export const useDeviceLoadingStatus = () => useSelector(state => ({
    loading: state.devices.isLoading,
    success: state.devices.success,
    error: state.devices.error,
}));

export const useDeviceSlice = () => ({
    unlockDevice: useUnlockDevice(),
    lockDevice: useLockDevice(),
    lockDeviceFirstTime: useLockDeviceFirstTime(),
    registerSingleWeight: useRegisterSingleWeight(),
    resetDevice: useResetDevice(),
    idleDevice: useIdleDevice(),
    confirmResetDevice: useConfirmResetDevice(),
    deviceLoadingStatus: useDeviceLoadingStatus(),
});