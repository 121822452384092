/**
 * *************************************
 *  SHADAPPS CONFIDENTIAL
 *  __________________
 *
 *  Created by Vittorio Tauro
 *
 *  2022 (c) ShadApps Srl
 *  All Rights Reserved.
 *
 *  NOTICE:  All information contained herein is, and remains
 *  the property of ShadApps Srl and its suppliers,
 *  if any. The intellectual and technical concepts contained
 *  herein are proprietary to ShadApps Srl.
 *  and its suppliers and may be covered by Italian, European and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from ShadApps Srl.
 * *************************************
 * */

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
// utils

import axios from '../../utils/axios';
//
import { dispatch } from '../store';
// routes
import { PATH_API } from '../../routes/api';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    success: false,
    locations: [],
    location: [],
};

const slice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.success = false;
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET locationS
        getLocationsSuccess(state, action) {
            state.isLoading = false;
            state.locations = action.payload;
        },

        // CREATE location
        createLocationSuccess(state, action) {
            const newLocation = action.payload;
            state.success = true;
            state.isLoading = false;
            state.locations = [...state.locations, newLocation];
        },

        // UPDATE location
        updateLocationSuccess(state, action) {
            const location = action.payload;
            const updateLocation = state.locations.map((_location) => {
                if (_location._id === location._id) {
                    return location;
                }
                return _location;
            });

            state.success = true;
            state.isLoading = false;
            state.locations = updateLocation;
        },

        // DELETE location
        deleteLocationSuccess(state, action) {
            const { id } = action.payload;
            const deleteLocation = state.locations.filter((location) => location._id !== id);
            state.locations = deleteLocation;
            state.isLoading = false;
            state.success = true;
        },

        // SELECT location
        selectLocation(state, action) {
            const id = action.payload;
            state.isOpenModal = true;
            state.error = null;
            state.success = false;
            state.selectedId = id;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectLocation, createLocationSuccess, updateLocationSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getLocations() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(PATH_API.locations);
            dispatch(slice.actions.getLocationsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getLocation(objectId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${PATH_API.locations}/${objectId}`);
            dispatch(slice.actions.getLocationsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function createLocation(newLocation) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(PATH_API.locations, newLocation);
            dispatch(slice.actions.createLocationSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateLocation({ id, updates }) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${PATH_API.locations}/${id}`, updates);
            dispatch(slice.actions.updateLocationSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function deleteLocation(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.delete(`${PATH_API.locations}/${id}`);
            dispatch(slice.actions.deleteLocationSuccess({ id }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
