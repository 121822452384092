import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';
import { it } from './it';
import { en } from './en';

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            en,
            it,
        },

        lng: 'en', // if you're using a language detector, do not define the lng option
        // fallbackLng: 'en',
        returnEmptyString: false, // don't return an empty string
        returnNull: false, // don't return null
        returnObjects: false, // important if you have nested JSON objects
        keySeparator: false, // set to false if you use keys that could have dots or other separators

        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
    });
